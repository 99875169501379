<template>
  <form-layout
    :is-loading="state.isInitialLoading"
    title="User Notifications"
    icon="circle_notifications"
  >
    <errors :errors="state.errors" />

    <div class="flex flex-col space-y-6">
      <div class="flex items-center justify-between px-2">
        <div class="flex flex-col">
          <p
            id="privacy-option-1-label"
            class="font-semibold text-big-stone"
            :class="{ 'font-bold': state.smsEnabled, 'font-semibold': !state.smsEnabled }"
          >
            Enable SMS Alerts
          </p>
        </div>
        <div class="flex justify-end">
          <toggle
            v-model="state.smsEnabled"
            @change="saveSmsState(state.smsEnabled)"
          />
        </div>
      </div>

      <div class="flex items-center justify-between px-2">
        <div class="flex flex-col">
          <p
            id="privacy-option-1-label"
            class="font-semibold text-big-stone"
            :class="{ 'font-bold': state.form.enabled, 'font-semibold': !state.form.enabled }"
          >
            Enable user notifications
          </p>
        </div>
        <div class="flex justify-end">
          <toggle
            v-model="state.form.enabled"
            :disabled="true"
          />
        </div>
      </div>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
      >
        <div v-show="state.form.enabled">
          <div class="flex flex-col lg:flex-row lg:space-x-4 xl:space-x-6 border-t border-gray-100">
            <div class="w-full lg:w-5/12 pt-4 xl:pt-6">
              <div class="rounded-md bg-gray-50 flex-col space-y-4 xl:space-y-6 p-2 md:p-4">
                <div class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <p
                      id="privacy-option-1-label"
                      class="text-big-stone"
                      :class="{ 'font-semibold': state.form.notify_via_sms, 'font-regular': !state.form.notify_via_sms }"
                    >
                      Notify via SMS
                    </p>
                    <div>{{ state.form.phone_number }}</div>
                  </div>
                  <div class="flex justify-end">
                    <toggle
                      v-model="state.form.notify_via_sms"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex flex-col">
                    <p
                      id="privacy-option-1-label"
                      class="text-big-stone"
                      :class="{ 'font-semibold': state.form.notify_via_email, 'font-regular': !state.form.notify_via_email }"
                    >
                      Notify via email
                    </p>
                  </div>
                  <div class="flex justify-end">
                    <toggle
                      v-model="state.form.notify_via_email"
                      :disabled="true"
                    />
                  </div>
                </div>

                <div
                  class=""
                  :class="{ '': state.form.notify_via_pushover }"
                >
                  <div class="flex items-center justify-between">
                    <p
                      id="privacy-option-1-label"
                      class="text-big-stone"
                      :class="{ 'font-semibold': state.form.notify_via_pushover, 'font-regular': !state.form.notify_via_pushover }"
                    >
                      Notify via Pushover
                    </p>
                    <div class="flex justify-end">
                      <toggle
                        v-model="state.form.notify_via_pushover"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class=""
                  :class="{ '': state.form.quiet_hours_enabled }"
                >
                  <div class="flex items-center justify-between">
                    <p
                      id="privacy-option-1-label"
                      class="text-big-stone"
                      :class="{ 'font-semibold': state.form.quiet_hours_enabled, 'font-regular': !state.form.quiet_hours_enabled }"
                    >
                      Quiet Hours
                    </p>
                    <div class="flex justify-end">
                      <toggle
                        v-model="state.form.quiet_hours_enabled"
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <form
                    v-if="state.form.quiet_hours_enabled"
                    class="flex items-end space-x-2 md:space-x-4 mt-2 p-2 bg-gray-100"
                  >
                    <div class="w-1/2">
                      <time-picker
                        v-model="state.form.quiet_hours_start"
                        :disabled="true"
                        label="Starts At"
                      />
                    </div>
                    <div class="w-1/2">
                      <time-picker
                        v-model="state.form.quiet_hours_end"
                        :disabled="true"
                        label="Ends At"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="w-full lg:w-7/12 pt-4 xl:pt-6">
              <div class="">
                <div class="flex">
                  <div class="w-2/5" />
                  <div class="flex-1 flex space-x-4 items-center justify-end">
                    <div class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center">
                      Notify When Triggered
                    </div>
                    <div class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center">
                      Notify When Resolved
                    </div>
                    <div
                      v-if="state.form.quiet_hours_enabled"
                      class="w-1/3 text-xs leading-tight md:text-sm md:leading-tight text-center"
                    >
                      Notify During Quiet Hours
                    </div>
                  </div>
                </div>
                <ul class="divide-y divide-gray-50">
                  <li
                    v-for="alert in state.form.alerts"
                    :key="alert.alert_id"
                    class="py-4 flex items-center justify-between hover:bg-gray-50 hover:bg-opacity-10 rounded-md"
                  >
                    <div class="flex flex-col w-2/5">
                      <p
                        id="privacy-option-1-label"
                        class="text-sm leading-tight text-big-stone pl-2"
                      >
                        {{ alert.name }}
                      </p>
                    </div>
                    <div class="flex flex-1 space-x-4 items-center justify-end">
                      <div class="w-1/3 text-center">
                        <toggle
                          v-model="alert.notify_when_triggered"
                          :disabled="true"
                        />
                      </div>
                      <div class="w-1/3 text-center">
                        <toggle
                          v-model="alert.notify_when_resolved"
                          :disabled="true"
                        />
                      </div>
                      <div
                        v-if="state.form.quiet_hours_enabled"
                        class="w-1/3 text-center"
                      >
                        <toggle
                          v-model="alert.notify_during_quiet_hours"
                          :disabled="true"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </form-layout>
</template>

<script>
import { reactive, watch } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import date from '@/helpers/date.js'
import FormLayout from '@/components/form/Layout.vue'
import Toggle from '@/components/form/Toggle.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import Errors from '@/components/form/Errors.vue'

export default {
  name: 'ProfileAlertsForm',

  components: {
    FormLayout,
    Errors,
    Toggle,
    TimePicker,
  },

  props: ['alerts', 'smsEnabled', 'email'],

  setup(props) {
    const state = reactive({
      form: {},
      isInitialLoading: true,
      smsEnabled: props.smsEnabled,
    })

    const saveSmsState = async (isEnabled) => {
      try {
        await http.post(`users/${props.email}/sms`, {
          sms_enabled: isEnabled
        })
        notification.success('Success', `<strong>SMS Alerts</strong> have been <strong>${isEnabled ? 'enabled' : 'disabled'}</strong>`, null)
      } catch (e) {
        state.smsEnabled = !isEnabled
        notification.error('Error', 'SMS Alerts could not be changed', false)
      }
    }

    watch(
      () => props.alerts,
      (data) => {
        state.form = {
          ...data,
          quiet_hours_start: date.alertsTimeFormat(data.quiet_hours_start).fromApi(),
          quiet_hours_end: date.alertsTimeFormat(data.quiet_hours_end).fromApi(),
        }
        state.isInitialLoading = false
      }
    )

    watch(
      () => props.smsEnabled,
      (value) => {
        state.smsEnabled = value
      }
    )

    return {
      state,
      saveSmsState,
    }
  }
}
</script>
