<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <div class="flex flex-col md:flex-row md:justify-between md:items-center border-b pb-2">
      <h2 class="font-bold text-xl sm:text-2xl">
        {{ state.user.username ?? state.user.email }}
      </h2>

      <div class="inline-block">
        <router-link
          :to="{ name: 'SettingsUsersIndex'}"
          class="group text-sm flex items-center"
        >
          <span class="material-icons text-big-stone">chevron_left</span>
          <span class="group-hover:underline">Back</span>
        </router-link>
      </div>
    </div>

    <alert-response-warning
      v-if="! state.hasAccess"
      :error="state.accessError"
    />

    <div
      v-else
      class="space-y-6 xl:space-y-8 mt-4 xl:mt-6"
    >
      <div class="bg-white rounded-md shadow-sm relative">
        <div
          v-if="state.isLoading"
          class="absolute inset-0 flex justify-center items-center bg-white"
        >
          <icon-loading class="w-6 h-6 text-blumine" />
        </div>

        <div class="flex flex-col">
          <div class="flex p-4 xl:px-6 border-b border-gray-200">
            <div class="w-1/3 font-semibold">
              Email:
            </div>
            <div class="flex-1">
              {{ state.user.email }}
            </div>
          </div>
          <div class="flex border-b p-4 xl:px-6">
            <div class="w-1/3 font-semibold">
              Display name:
            </div>
            <div class="flex-1">
              {{ state.user.username ?? '-' }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="bg-white rounded-md shadow-sm">
          <div class="border-b p-4 xl:px-6 flex items-center justify-between">
            <div class="flex items-center">
              <span class="material-icons text-big-stone">swap_horiz</span>
              <h2 class="text-lg font-bold ml-1">
                Assign Machines
              </h2>
            </div>
            <span class="hidden material-icons text-big-stone">expand_less</span>
          </div>

          <div class="p-4 xl:p-6 relative">
            <div
              v-if="state.isLoadingMachines || state.isLoading"
              class="absolute inset-0 flex justify-center items-center bg-white"
            >
              <icon-loading class="w-6 h-6 text-blumine" />
            </div>
            <div class="flex flex-wrap md:flex-nowrap md:space-x-4">
              <div class="w-full md:w-1/2">
                <div class="p-2 md:p-4">
                  <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">
                    Machine List
                  </h3>
                  <div class="flex items-center space-x-2">
                    <input
                      v-model="state.unAssignedMachinesQuery"
                      type="search"
                      placeholder="Search..."
                      class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1"
                    >
                    <button
                      class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out"
                      @click="assignAllMachines"
                    >
                      <span v-if="state.unAssignedMachinesQuery.length === 0">Assign all</span>
                      <span v-else>Assign filtered</span>
                    </button>
                  </div>
                  <div class="mt-4 px-2">
                    <div
                      v-for="machine in unAssignedMachinesPaginated.items"
                      :key="machine.location_id"
                    >
                      <label>
                        <input
                          v-model="state.selectedMachines"
                          type="checkbox"
                          :value="machine.location_id"
                          class="rounded"
                        >
                        <span class="ml-2">{{ machine.name }}</span>

                        <span class="text-sm block ml-6 md:inline md:ml-2">
                          <span
                            v-if="machine.owner"
                            class="text-gray-600"
                          >{{ machine.owner }}</span>
                          <span
                            v-else
                            class="text-gray-400"
                          >(No Billing Responsibility)</span>
                        </span>

                      </label>
                    </div>
                    <div v-if="unAssignedMachinesPaginated.items.length === 0">
                      All Machines Assigned
                    </div>
                  </div>
                  <div class="mt-4">
                    <pagination :paginator="unAssignedMachinesPaginated" />
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 mt-4 md:mt-0">
                <div class="bg-gray-50 p-2 md:p-4 rounded-md">
                  <h3 class="text-sm font-bold uppercase text-big-stone border-b border-big-stone pb-2 mb-4 border-opacity-25">
                    Assigned
                  </h3>
                  <div class="flex items-center space-x-2">
                    <input
                      v-model="state.assignedMachinesQuery"
                      type="search"
                      placeholder="Search..."
                      class="border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out flex-1"
                    >
                    <button
                      class="font-semibold text-sm px-2 py-2 rounded-lg bg-big-stone text-white hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out"
                      @click="unAssignAllMachines"
                    >
                      <span v-if="state.assignedMachinesQuery.length === 0">Un-Assign all</span>
                      <span v-else>Un-Assign filtered</span>
                    </button>
                  </div>
                  <div class="mt-4 px-2 gap-3 flex flex-col">
                    <div
                      v-for="machine in assignedMachines"
                      :key="machine.location_id"
                      class="flex justify-between"
                    >
                      <label class="flex items-center">
                        <input
                          v-model="state.selectedMachines"
                          type="checkbox"
                          :value="machine.location_id"
                          class="rounded text-blumine"
                        >
                        <div class="ml-2 flex items-center flex-wrap">
                          <span class="shrink-0 mr-2">{{ machine.name }}</span>
                          <span
                            v-if="machine.owner"
                            class="text-sm text-gray-600 block shrink"
                          >{{ machine.owner }}</span>
                        </div>
                      </label>
                      <div class="w-28 flex-none">
                        <select
                          class="block text-sm pl-2 pt-1 pb-1 w-full border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out"
                          v-model="state.roleMachines[machine.location_id]">
                          <option value="user">User</option>
                          <option value="technician">Technician</option>
                          <option v-if="!machine.owner || machine.owner === state.user.email" value="owner">Owner</option>
                        </select>
                      </div>
                    </div>
                    <div v-if="assignedMachines.length === 0">
                      No Assigned Machines
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 flex justify-end space-x-4">
          <router-link
            :to="{ name: 'SettingsUsersIndex'}"
            class="flex items-center justify-center py-2 px-4 border border-transparent rounded-md text-big-stone font-semibold bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:shadow-outline-gray-100 active:bg-gray-600 transition duration-150 ease-in-out"
          >
            Cancel
          </router-link>
          <submit-button
            :is-loading="state.isSaving"
            @click="saveMachinesChanges"
          >
            Save
          </submit-button>
        </div>
      </div>

      <alerts :alerts="state.user.alerts" :sms-enabled="state.user.sms_enabled" :email="state.user.email" />
    </div>
  </layout>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import { highlightInString, sortAlpha } from '@/helpers/utils.js'
import Layout from '@/layouts/Default.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import { IconLoading } from '@/components/icons'
import Alerts from '@/views/settings/users/Alerts.vue'
import SettingsNav from '@/components/settings/Nav'
import SelectBox from '@/components/form/Select.vue'

import { Paginator } from '@/services/paginator.js'
import Pagination from '@/components/Pagination.vue'

export default {
  name: 'SettingsUsersShow',

  components: {
    Layout,
    AlertResponseWarning,
    SubmitButton,
    IconLoading,
    Alerts,
    SettingsNav,
    SelectBox,
    Pagination,
  },

  setup() {
    const route = useRoute()
    const state = reactive({
      user: {},
      machines: [],
      hasAccess: true,
      accessError: null,
      isLoading: true,
      allowedMachines: [],
      selectedMachines: [],
      roleMachines: {},
      assignedMachinesQuery: '',
      unAssignedMachinesQuery: '',
    })
    const paginator = new Paginator().setPerPage(10)

    const updateRoleMachines = () => {
      state.roleMachines = state.machines.reduce((previous, current) => {
        let role = 'user'
        const machine = state.user.allowed_locations.find(m => m.location_id === current.location_id)
        if (machine) {
          if (current.owner === state.user.email) role = 'owner'
          else if (machine.is_technician) role = 'technician'
        }
        return { ...previous, [current.location_id]: role }
      }, {})
    }

    const getUser = async (skipLoading) => {
      if (!skipLoading) state.isLoading = true

      try {
        const { data } = await http.get(`users/${route.params.id}`)

        state.user = data
        state.selectedMachines = data.allowed_locations.map(l => l.location_id)
      } catch (e) {
        state.hasAccess = false
        state.accessError = e
      }

      state.isLoading = false
    }

    const getMachines = async () => {
      state.isLoadingMachines = true

      try {
        const { data } = await http.get('machine')
        state.machines = data.locations
      } catch (e) {
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }

      state.isLoadingMachines = false
    }

    const saveAssignedMachines = async () => {
      const ownershipUpdates = []
      for (let machine of state.machines) {
        let machineId = machine.location_id
        const owned = state.roleMachines[String(machineId)] && state.roleMachines[String(machineId)] === 'owner'
        if (
          state.selectedMachines.includes(machineId)
          && machine.owner !== state.user.email
          && owned
        ) {
          ownershipUpdates.push({
            locationid: machineId,
            owner: state.user.email
          })
        }
        else if (
          machine.owner === state.user.email
          && (
            !owned
            || !state.selectedMachines.includes(machineId)
          )
        ) {
          ownershipUpdates.push({
            locationid: machineId,
            owner: null
          })
        }
      }

      try {
        if (ownershipUpdates.length > 0) {
          await http.post('/machine/owners', {
            setowners: ownershipUpdates
          })
        }

        const locations = state.selectedMachines.map(id => ({
          location_id: id,
          is_technician: state.roleMachines[String(id)] === 'technician',
        }))
        await http.post(`users/${route.params.id}/machines`, { locations })
        notification.success('Success', 'Machines have been assigned to the user')
      } catch (e) {
        if (e.response) {
          notification.error(e.response.data.Message)
        } else {
          notification.error('Something went wrong. Please, try again.')
        }
      }
    }

    const saveMachinesChanges = async () => {
      state.isSaving = true

      await saveAssignedMachines()

      state.isSaving = false
      getUser(true)
      getMachines()
    }

    getUser()
    getMachines()

    const assignAllMachines = () => {
      if (state.unAssignedMachinesQuery.length > 0) {
        state.selectedMachines = state.selectedMachines.concat(
          unAssignedMachines.value.map((machine) => machine.location_id)
        )
      }
      else {
        state.selectedMachines = state.machines.map((machine) => machine.location_id)
      }
    }

    const unAssignAllMachines = () => {
      if (state.assignedMachinesQuery.length > 0) {
        const idsToRemove = assignedMachines.value.map((machine) => machine.location_id)
        state.selectedMachines = state.selectedMachines.filter((machineId) => {
          return !idsToRemove.includes(machineId)
        })
      }
      else {
        state.selectedMachines = []
      }
    }

    const assignedMachines = computed(() => {
      const machines = state.machines.filter((machine) => {
        return state.selectedMachines.includes(machine.location_id)
          && (
            machine.name.toLowerCase().includes(state.assignedMachinesQuery.toLowerCase())
            || machine.owner?.toLowerCase().includes(state.assignedMachinesQuery.toLowerCase())
          )
      })

      return sortAlpha(machines, 'name')
    })

    const unAssignedMachines = computed(() => {
      const machines = state.machines.filter((machine) => {
        return ! state.selectedMachines.includes(machine.location_id)
          && (
            machine.name.toLowerCase().includes(state.unAssignedMachinesQuery.toLowerCase())
            || machine.owner?.toLowerCase().includes(state.unAssignedMachinesQuery.toLowerCase())
          )
      })

      return sortAlpha(machines, 'name')
    })

    const unAssignedMachinesPaginated = computed(() => {
      return paginator.setItems(unAssignedMachines.value)
    })

    watch(
      () => [state.user, state.machines],
      () => {
        if (state.user.email && state.machines.length > 0) {
          updateRoleMachines()
        }
      }
    )

    return {
      state,
      highlightInString,
      assignedMachines,
      unAssignedMachines,
      unAssignedMachinesPaginated,
      saveMachinesChanges,
      assignAllMachines,
      unAssignAllMachines,
    }
  }
}
</script>
